import { GRID_SIZE } from '../CONST'
import { Cell, Player } from './Cell'

export class Field {
  dimension = GRID_SIZE
  cells = []
  currentPlayer = Player.First
  constructor() {
    this.initField()
  }

  initField() {
    let tmp = []
    for (let i = 0; i < this.dimension; i++) {
      let a = []
      for (let j = 0; j < this.dimension; j++) {
        a.push(new Cell(j, i, this))
      }
      tmp.push(a)
    }

    this.cells = tmp
  }

  //switch player
  togglePlayer() {
    this.currentPlayer =
      this.currentPlayer === Player.First ? Player.Second : Player.First
  }
}
