import { useEffect, useState } from 'react'
import FieldComponent from './components/FieldComponent'
import { Field } from './model/Field'

function App() {
  const [field, setField] = useState(null)

  useEffect(() => {
    setField(new Field())
  }, [])

  return field ? <FieldComponent field={field} /> : ''
}

export default App
