import { Player } from '../model/Cell'
import { useState } from 'react'
import CanvasComponent from './CanvasComponent'
const CellComponent = ({ cell, reloadField }) => {
  const [state, setState] = useState(cell.player)

  function toggleCellComponent() {
    if (state === Player.No) {
      setState(cell.field.currentPlayer)
      cell.toggleCell()
      if (cell.field?.win) reloadField()
    }
  }

  return (
    <div className="cell" onClick={toggleCellComponent}>
      <CanvasComponent color={state} cell={cell} />
    </div>
  )
}
export default CellComponent
