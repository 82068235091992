export const Player = {
  No: 0,
  First: 1,
  Second: 2,
}

export const Colors = ['gray', 'red', 'blue']

export class Cell {
  x
  y
  player = Player.No
  field
  green = false

  constructor(x, y, field) {
    this.x = x
    this.y = y
    this.field = field
  }

  toggleCell() {
    this.player = this.field.currentPlayer
    this.field.togglePlayer()
  }
}
