import { useRef, useEffect } from 'react'
import { CELL_SIZE, DOT_SIZE, LINE_WIDTH } from '../CONST'
import { Player, Colors } from '../model/Cell'

const CanvasComponent = ({ cell, color }) => {
  const canvasRef = useRef(null)
  const contextRef = useRef(null)

  const drawBaseGrid = () => {
    const w = CELL_SIZE
    const h = CELL_SIZE
    const canvas = canvasRef.current
    canvas.width = w
    canvas.height = h

    const context = canvas.getContext('2d')
    contextRef.current = context

    contextRef.current.lineCap = 'round'
    contextRef.current.strokeStyle = 'black'
    contextRef.current.lineWidth = LINE_WIDTH
    contextRef.current.strokeStyle = 'darkgray'

    contextRef.current.beginPath()
    contextRef.current.moveTo(0, h / 2)
    contextRef.current.lineTo(w, h / 2)
    contextRef.current.moveTo(w / 2, 0)
    contextRef.current.lineTo(w / 2, h)
    contextRef.current.stroke()
  }

  const putDot = (color) => {
    contextRef.current.strokeStyle = color
    const centerX = canvasRef.current.clientWidth / 2
    const centerY = canvasRef.current.clientHeight / 2
    const radius = DOT_SIZE
    contextRef.current.beginPath()
    contextRef.current.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    contextRef.current.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    contextRef.current.fillStyle = color
    contextRef.current.fill()
    contextRef.current.stroke()
  }

  useEffect(() => {
    drawBaseGrid()
  }, [])

  // useEffect(() => {
  //   console.log(cell?.player)
  //   // drawBaseGrid()
  if (cell?.player && cell?.player !== Player.No) putDot(Colors[cell?.player])
  // }, [color])

  return <canvas ref={canvasRef}></canvas>
}

export default CanvasComponent
