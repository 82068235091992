import React, { useState, useRef, useEffect } from 'react'
import { CELL_SIZE, GRID_SIZE } from '../CONST'

import CellComponent from './CellComponent'

const FieldComponent = ({ field }) => {
  // eslint-disable-next-line
  const [state, setState] = useState(false)
  const fieldRef = useRef(null)

  function reloadField() {
    setState(true)
  }

  useEffect(() => {
    fieldRef.current.style.setProperty('--CELL_SIZE', CELL_SIZE + 'px')
    fieldRef.current.style.setProperty('--GRID_SIZE', GRID_SIZE)
  }, [])

  return (
    <div className="field" ref={fieldRef}>
      {field.cells.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {value.map((value, index) => {
              return (
                <CellComponent
                  cell={value}
                  key={index}
                  reloadField={reloadField}
                />
              )
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default FieldComponent
